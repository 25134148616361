import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clientVariableActions } from "../actions/clientVariable.actions";
import Utils from "../utils";

const useClientVariableTitle = (variableId, user) => {
  const [clientVariableTitle, setClientVariableTitle] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const clientVariableStore = useSelector((state) => state.clientVariable);

  useEffect(() => {
    const id = variableId ?? Utils.getQueryStringParams(location)["variableId"];

    if (id) {
      const variableInStore = clientVariableStore.paginatedResult?.data?.find(
        (v) => v.variableId === id
      );

      if (variableInStore) {
        setClientVariableTitle(
          `${variableInStore?.variableNumber} - ${variableInStore?.variableName}`
        );
      } else {
        dispatch(
          clientVariableActions.get({
            variableId: id,
            clientId: user.clientId,
            clientGuid: user.clientGuid,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    dispatch,
    clientVariableStore.paginatedResult?.data,
    variableId,
  ]);

  useEffect(() => {
    const id = variableId ?? Utils.getQueryStringParams(location)["variableId"];
    const variableInStore = clientVariableStore.paginatedResult?.data?.find(
      (v) => v.variableId === id
    );

    if (variableInStore) {
      setClientVariableTitle(
        `${variableInStore?.variableNumber} - ${variableInStore?.variableName}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientVariableStore, location, variableId]);

  return {
    clientVariableTitle: `Variable ${clientVariableTitle}`,
    clientVariableTitleLoading: clientVariableStore.loading,
  };
};

export default useClientVariableTitle;
