import React, { useEffect, useState } from "react";
import Title from "../common/Title";
import { LuVariable } from "react-icons/lu";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { clientVariableActions } from "../../actions/clientVariable.actions";
import { useDispatch, useSelector } from "react-redux";
import PlaceHolderLines from "../common/PlaceHolderLines";
import useClientVariableTitle from "../../hooks/clientVariableTitle";
import Subtitle from "../common/Subtitle";

function VariableForm(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [variableId, setVariableId] = useState("");
  const [variableNumber, setVariableNumber] = useState("");
  const [variableName, setVariableName] = useState("");
  const [clientId, setClientId] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { clientVariableTitle, clientVariableTitleLoading } =
    useClientVariableTitle(null, props.user);

  const clientVariableReducer = useSelector((state) => state.clientVariable);

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    if (queryParams["variableId"]) {
      queryParams["clientId"] = props.user.clientId;
      queryParams["clientGuid"] = props.user.clientGuid;
      setVariableId(queryParams["variableId"]);
      dispatch(clientVariableActions.get(queryParams));
    } else {
      setVariableId("");
      setVariableNumber("");
      setVariableName("");
      setClientId("");
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (
      variableId &&
      clientVariableReducer.paginatedResult &&
      clientVariableReducer.paginatedResult.data &&
      clientVariableReducer.paginatedResult.data[0]?.variableName
    ) {
      setVariableNumber(
        clientVariableReducer.paginatedResult.data[0].variableNumber
      );
      setVariableName(
        clientVariableReducer.paginatedResult.data[0].variableName
      );
      setClientId(clientVariableReducer.paginatedResult.data[0].clientId);
    }
    // eslint-disable-next-line
  }, [clientVariableReducer]);

  useEffect(() => {
    if (clientVariableReducer.success) {
      navigate("/variable");
    }
    // eslint-disable-next-line
  }, [clientVariableReducer.success]);

  const handleSaveVariable = () => {
    setSubmitted(true);

    if (variableName) {
      if (variableId) {
        dispatch(
          clientVariableActions.update({
            variableNumber,
            variableName,
            clientId:
              props.user.clientId !== "" ? props.user.clientId : clientId,
            clientGuid: props.user.clientGuid,
          })
        );
      } else {
        dispatch(
          clientVariableActions.create({
            variableName,
            clientId: props.user.clientId,
            clientGuid: props.user.clientGuid,
          })
        );
      }
    }
  };

  const operation = !variableId ? "Add" : "Update";

  return (
    <>
      <Title
        text={`${operation} Variable`}
        icon={<LuVariable className="mb-1" />}
      />

      {!clientVariableReducer.loading && !clientVariableTitleLoading && (
        <Subtitle
          text={clientVariableTitle}
          icon={<LuVariable className="mb-1" />}
        />
      )}

      {clientVariableReducer.loading && <PlaceHolderLines count={4} />}

      {!clientVariableReducer.loading && (
        <>
          {!clientVariableReducer.success && (
            <>
              {clientVariableReducer.error && (
                <Alert variant={"danger"}>
                  An error has occurred. Please try again later.
                </Alert>
              )}

              <Form.Group controlId="formVariableName" className="mb-3">
                <Form.Label>Variable Name</Form.Label>
                <Form.Control
                  type="text"
                  value={variableName}
                  onChange={(e) => setVariableName(e.target.value)}
                  className={submitted && !variableName ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  <small>Please provide the variable name.</small>
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="outline-secondary"
                type="button"
                className="me-3 mt-3"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                onClick={handleSaveVariable}
                disabled={clientVariableReducer.loading}
              >
                {!clientVariableReducer.loading ? (
                  <>{operation} Variable</>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default VariableForm;
