import React from "react";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { Handle } from "reactflow";
import { TbStepInto } from "react-icons/tb";

export const GeneralNode = ({ data }) => {
  const popover = (
    <Popover id="popover-basic" style={{ width: "350px" }}>
      <Popover.Header as="h3">
        <div>
          <TbStepInto className="me-2" />
          <strong>{data.step.stepNumber}</strong>
          {data.step.system ? <small> - {data.step.system}</small> : <></>}
          <Badge bg="primary" className="float-end">
            {data.step.action}
          </Badge>
        </div>
      </Popover.Header>
      <Popover.Body>{data.step.stepDescription}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="right"
      overlay={popover}
    >
      <div
        style={{
          padding: 10,
          border: `1px solid ${data.isError ? "red" : "black"}`,
          width: "300px",
          borderRadius: 3,
        }}
        className="bg-light"
        onClick={() => data.handleOnClick(data.step.stepNumber)}
      >
        <div>
          <TbStepInto className="me-2" />
          <strong>{data.step.stepNumber}</strong>
          {data.step.system ? <small> - {data.step.system}</small> : <></>}
          <Badge bg="primary" className="float-end">
            {data.step.action}
          </Badge>
        </div>
        <Handle type="source" position="bottom" id="bottom-source" />
        <Handle type="target" position="top" id="top-target" />
        <Handle type="source" position="left" id="left-source" />
        <Handle type="target" position="left" id="left-target" />
        <Handle type="source" position="right" id="right-source" />
        <Handle type="target" position="right" id="right-target" />
      </div>
    </OverlayTrigger>
  );
};

export const getNodes = (steps, handleOnClick) => {
  return steps.map((s, i) => {
    return {
      id: s.stepNumber,
      type: "generalNode",
      data: {
        step: s,
        isError: steps.some((os) => os.errorStepId === s.stepNumber),
        handleOnClick: handleOnClick,
      },
      position: { x: 450, y: i * 100 },
      draggable: false,
    };
  });
};

export const getEdges = (steps) => {
  let edges = [];

  steps.forEach((s) => {
    let nextStep = steps.find((ns) => ns.stepNumber === s.nextStepId);
    let conditionTrueStep = steps.find(
      (ns) => ns.stepNumber === s.conditionTruePath
    );
    let conditionFalseStep = steps.find(
      (ns) => ns.stepNumber === s.conditionFalsePath
    );
    let errorStep = steps.find((ns) => ns.stepNumber === s.errorStepId);

    let edgesForThisStep = [
      nextStep,
      conditionTrueStep,
      conditionFalseStep,
      errorStep,
    ];

    edgesForThisStep.forEach((e) => {
      if (e) edges.push(getEdge(s, e, steps));
    });
  });

  return edges;
};

const getEdge = (sourceStep, targetStep, steps) => {
  return {
    id: `e${sourceStep.stepNumber}-${targetStep.stepNumber}`,
    source: sourceStep.stepNumber,
    target: targetStep.stepNumber,
    markerEnd: {
      type: "arrowclosed",
    },
    type: "smoothstep",
    label: getEdgeLabel(sourceStep, targetStep),
    sourceHandle: `${
      getEdgeHandle(sourceStep, targetStep, steps) ?? "bottom-"
    }source`,
    targetHandle: `${
      getEdgeHandle(sourceStep, targetStep, steps) ?? "top-"
    }target`,
  };
};

const getEdgeLabel = (sourceStep, targetStep) => {
  if (targetStep && targetStep.dataStep === sourceStep.stepNumber)
    return `Data from ${targetStep.dataStep}`;

  if (targetStep && targetStep.stepNumber === sourceStep.errorStepId)
    return "On Error";

  if (targetStep && targetStep.stepNumber === sourceStep.conditionTruePath)
    return "True";

  if (targetStep && targetStep.stepNumber === sourceStep.conditionFalsePath)
    return "False";
};

const getEdgeHandle = (sourceStep, targetStep, steps) => {
  if (
    targetStep &&
    (targetStep.stepNumber === sourceStep.errorStepId ||
      targetStep.stepNumber === sourceStep.conditionFalsePath)
  )
    return "left-";

  if (
    targetStep &&
    (targetStep.stepOrder < sourceStep.stepOrder ||
      steps.some(
        (s) =>
          sourceStep.stepOrder < s.stepOrder &&
          targetStep.stepOrder > s.stepOrder &&
          targetStep.stepNumber !== s.stepNumber
      ))
  )
    return "right-";

  return null;
};
